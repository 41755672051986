import { NavLink } from "react-router-dom";
import styles from "./css/ExpedienteCard.module.css";

import { ModalityCard } from "./ModalityCard";
 
export function ExpedienteCard({ expediente }) {
	return (
		
		<li className={styles.expedienteCard}>
			<NavLink className={styles.none} to={"/expediente/" + expediente.hash_autenticidad}>
 
				<div className={styles.cardHeader}>
					<div className={styles.title}>
						<strong>{expediente.fecha}</strong> {expediente.modalidad}
					</div>
					<div className={styles.smallBlack}>{expediente.medico} {expediente.medico_apellidos}</div>
				</div>
				{/* <div className={styles.title}>{expediente.modalidad}</div> */}
        <ModalityCard image={expediente.logo}></ModalityCard>				

				<div className={styles.cardContent}>									
					<div className={`${styles.smallSubTitle} ${styles.smallCapitalize}`}>{expediente.procedimiento}</div>

					<div className={`${styles.smallBlack} ${styles.smallCapitalize}`}>{expediente.paciente + ' ' + expediente.apellidos}</div>
					<div className={`${styles.smallSubTitle} ${styles.smallCapitalize}`}>{expediente.medico_radiologo}</div>
				</div> 
			</NavLink>
		</li>
	);
}