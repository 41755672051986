/**
 *
 * @returns
 */
//import axios from "./../api/axios";
import axios, { BASE_URL } from "./../api/axios"
import { useState } from "react";

import styles from "./css/Question.module.css";

const QuestionMessageFinal = ({ setHandleEvent, data, setAvance, resultModalities }) => {
	const [message, setMessage] = useState("");

	const handleMessage = (event) => {
		setMessage(event.target.value);

		setHandleEvent(prevState => ({
			...prevState,
			value04 : event.target.value}));	
	};


	const doSendData = async (dataSurvey) => {
		try {
			//console.log(dataSurvey);

			const response = await axios.post(BASE_URL + "/survey", dataSurvey, {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
				withCredentials: true,
			});

			console.log(response.data);
			localStorage.setItem("survey", "true");
		} catch (err) {
			console.log(err);
		}
	}; 

	const handleEvent = async (ev) => {


		const dataSend = {
			numeroAcceso: data?.numeroAcceso ? data?.numeroAcceso : -1,
			value01: data.value01,
			value02: data.value02,
			value03: data.value03,
			value04: data.value04,			
			phone: data.phone,
			patient: data.patient,
			resultModalities
		};

		console.log(dataSend);						
		await doSendData(dataSend);

		setAvance(9);		

	};

	return (
		<>
			<div className={styles.containerQuestion}>
				<div className={styles.titleQuestion}>
					Si así lo desea, escriba algún comentario o sugerencia.
				</div>
				<div className={styles.containerMessage}>
					<textarea
						className={styles.mensajeFinal}
						value={message}
						onChange={(ev) => handleMessage(ev)}
						rows={7}
						cols={400}
						maxLength={400}
						placeholder="Máximo 400 letras"
					></textarea>

 
						
						<div className={styles.controlBtn} onClick={(e) => handleEvent(e)}>
							Finalizar
						</div>					
						 

					
				</div>
			</div>
		</>
	);
};

export default QuestionMessageFinal;
