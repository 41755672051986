import { useEffect, useState } from "react";
import { useQuery } from "../hooks/useQuery";
import { ExpedienteCard } from "./../components/ExpedienteCard";
import styles from "./css/ExpedientePage.module.css";
import { Spinner } from "./../components/Spinner";
import { Empty } from "../components/Empty";
import { TypeRole } from "../config/TiposDatos";

import AlertCustom from "../config/AlertCustom";
import { TipoMensaje } from "../config/TiposDatos";


import axios, { BASE_URL } from "./../api/axios";

export function ExpedientePage(
	{ dateInitValue, 
		dateFinishValue, 
		nameValue, 
		executeFinder
	}

) {
	const [expedientes, setExpedientes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const query = useQuery();
	const search = query.get("busqueda");

		useEffect(() => {
		setIsLoading(true);

		
    ( async () => {
		const role = JSON.parse(localStorage.getItem("ciemRole"));
		setIsLoading(true);	
		const bodyRequest = {
			cadenaBusqueda: nameValue, // search ? search : '',
      perfilID : role.perfilDesc === TypeRole.ROLE_MEDIC ? role.perfilID : -1, // Almacena la clave medico que se retorna de la API y a la vez la enviamos en la petición para realizar la busqueda de un medico en particular.
                                                                               // cuando el role es invitado o paciente enviamos menos 1 (-1)
      ClaveUsuario : role.ClaveUsuario,                                                                              

			dateInitValue, 		// 	: convertDateToInt(dateInitValue),
			dateFinishValue, 	// : convertDateToInt(dateFinishValue),
			

			perfilDesc:
				role.perfilDesc === TypeRole.ROLE_MEDIC
					? TypeRole.ROLE_MEDIC
					: role.perfilDesc === TypeRole.ROLE_PATIENT
					? TypeRole.ROLE_PATIENT
					: TypeRole.ROLE_GUESS,

		}; 

      try {
        const response = await axios.put(
          BASE_URL + "/procedures",
          bodyRequest,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
								
        setExpedientes(response?.data);
				setIsLoading(false);
      } catch (err) {
        if (!err?.response) {
          AlertCustom(TipoMensaje.ERROR, "El servidor web no responde");
        } else if (err.response?.status === 401) {
          AlertCustom(
            TipoMensaje.ERROR, 'No se encontraron coindencias'						
          );
        } else if (err.response?.status === 400) {
          AlertCustom(TipoMensaje.ERROR, "Acceso no autorizado");
        } else {
          AlertCustom(TipoMensaje.ERROR, "Fallo general");
        }
        setExpedientes({});
      }
    })();
	
             
		
	}, [executeFinder /*dateInitValue, dateFinishValue*/]);

	if (isLoading) {
		return <Spinner />;
	}
	
	if (!isLoading && expedientes?.length === 0) {
		return <Empty />;
	}
		

	return (
		<ul className={styles.expedienteGrid}>
			
			{expedientes && expedientes.map((exp) => (
				<ExpedienteCard key={exp.hash_autenticidad} expediente={exp} />
			))}
		</ul>
	);
}
