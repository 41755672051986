/**
 *
 * @returns
 */
 import styles from "./css/Question.module.css";

 const Question02 = ({setHandleEvent, setProgressBar, setAvance, referencedByApp }) => {

	const handleEvent = (value) => {

    referencedByApp ? setAvance(4) : setAvance(3)
    //setProgressBar(40);
 
		setHandleEvent(prevState => ({
			...prevState,
			value02 : value}));		
	}	

   return (
     <>
       <div className={styles.containerQuestion}>
         <div className={styles.titleQuestion}>
         ¿Las indicaciones proporcionadas por la recepcionista fueron claras?
         </div>
         <div className={styles.containerOptions}>
           <div className={styles.controlButton} onClick={() => handleEvent(1)}>Sí</div>
           <div className={styles.controlButton} onClick={() => handleEvent(0)}>No</div>
         </div>
       </div>
     </>
   );
 };
 
 export default Question02;
 