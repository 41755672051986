import styles from "./css/ModalityCard.module.css";

import modCR from "./../assets/modality/CR.png";
import modCT from "./../assets/modality/CT.png";
import modDM from "./../assets/modality/DM.png";
import modMG from "./../assets/modality/MG.png";
import modRM from "./../assets/modality/MR.png";
import modUS from "./../assets/modality/US.png";
import modDEF from "./../assets/modality/DEF.png";

export function ModalityCard({ image }) {
  return (
    <div className={styles.imageCard}>          
      {(() => {
        switch (image) {
          case 'CR':
            return <img alt="Modalidad" className={styles.image} src={modCR} />
          case 'CT':
            return <img alt="Modalidad" className={styles.image} src={modCT} />
          case 'DM':
            return <img alt="Modalidad" className={styles.image} src={modDM} />
          case 'MG':
            return <img alt="Modalidad" className={styles.image} src={modMG} />
          case 'MR':
            return <img alt="Modalidad" className={styles.image} src={modRM} />
          case 'US':
            return <img alt="Modalidad" className={styles.image} src={modUS} />
          default:
            return <img alt="Modalidad" className={styles.image} src={modDEF} />
        }
      })()}

    </div>
  )
}




// return (
//   <div className={styles.imageCard}>          
//     <img alt="Modalidad" className={styles.expedienteImage} src={modCR} />
//   </div>
// );