import { ToastContainer } from "react-toastify";


import Footer from "./components/Footer";

import GlobalProvider from "./context/GlobalProvider";
import { HashRouter, Navigate, Route, Routes, Router } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import Navbar from "./components/Navbar";
import RegisterPage from "./pages/RegisterPage";
import InvitadoPage from "./pages/InvitadoPage";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage";
import { Expedientes } from "./pages/Expedientes";
import { ExpedienteDetails } from "./components/ExpedienteDetails";
import { ExpedienteOnePage } from "./pages/ExpedienteOnePage";


import "./App.css";
import Valorame from "./pages/Valorame";

function App() {
	return (
		<div>
			<div className="grid-container">
				<GlobalProvider>
					<HashRouter>
						<Navbar />
						<Routes>
							<Route path="/" element={<InvitadoPage />} /> 
							<Route path="/login" element={<LoginPage />} />
							<Route path="/invitado" element={<InvitadoPage />} />
							<Route path="/recuperacion" element={<PasswordRecoveryPage />} />
							<Route path="/registro" element={<RegisterPage />} />
							<Route path="/expediente" element={<Expedientes />} />  
							<Route path="/expediente/:idHash" element={<ExpedienteDetails />} />	

							{/* 	Requerido en la modalidad, el paciente accede directamente 
										desde el enlace que le llega a su correo, no se requiere
									USUARIO y CONTRASEÑA				 */}
							<Route path="/invitado/:type/:hash" element={<ExpedienteOnePage />} />				


							{/* <Route path="*" element={<Navigate replace to="/" />} /> */}
						</Routes>
						<Footer />
						<Routes>
						<Route path="/valorame" element={<Valorame />} />				
						</Routes>
						</HashRouter>

				</GlobalProvider>
				<ToastContainer />
			</div>

		</div>
	);
}

export default App;
