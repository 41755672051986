import styles from "./css/FormExpediente.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, { BASE_URL } from "./../api/axios" 

import Modal from "./modal/Modal";
import stylesModal from "./modal/Modal.module.css";
import { ExpedientePDF } from './pdfcomponent/ExpedientePDF';
import { TypeRole } from "../config/TiposDatos";


import LinkAccess from './LinkAccess';

export function FormExpediente({expediente, claveExpediente, getRedirect, objetoExpediente, setIsLoading}) {
	
	const [viewLink, setViewLink] = useState(false);
	const [rolePatient, setRolePatient] = useState(false);


	useEffect( () => { 
		const role = JSON.parse(localStorage.getItem("ciemRole"));			
		setViewLink(role?.LinkVisible);
		setRolePatient(role?.perfilDesc === TypeRole.ROLE_PATIENT || role?.perfilDesc ===  TypeRole.ROLE_GUESS);
	}, []);
	 

	const [searchText, setSearchText] = useState("");
	const navigate = useNavigate();

	
	const [modalOpen, setModalOpen] = useState(false);
	const [sucess, setSuccess] = useState(false);

  const [infoShare, setInfoShare] = useState({});

	const [handlePhone, setHandlePhone] = useState('');

	const doClickShare = async () => {

		console.log(' EXPEDIENTE => ' + JSON.stringify(objetoExpediente));
		const bodyRequest = {
			idProcedimiento : objetoExpediente.cve_procedimiento,
			idPaciente : objetoExpediente.cve_paciente,
			idAccessNumber : objetoExpediente.numero_acceso
		}

		try {			 

			const response = await axios.post(BASE_URL + '/procedure/tagshare',
					bodyRequest,
					{
							withCredentials: true,
					}
			);

			setInfoShare(response.data);
			setModalOpen(true);
	} catch (err) {
			console.log(err);
	}
	}

	const handleShare = (event) => {
		setSuccess(true);		
		setModalOpen(false)

		const URL = `https://api.whatsapp.com/send?phone=52${handlePhone}&text=*${objetoExpediente.paciente}* ha compartido un estudio de imagenología. Para visualizarlo, ingrese a www.ciemradiologos.com, haciendo uso del Usuario *${infoShare.ciemTag}* Código *${infoShare.securityCode}*`;
		
		window.open(URL, '_blank', 'noopener,noreferrer');			
	}

	const [contentInterpretacion, setContentInterpretacion] = useState([]);


	const handleSubmit = (e) => {
		e.preventDefault();
		navigate("?busqueda=" + searchText);
	};


	//-----------------------------------------------------------------------------------------------------------------------
	// API Interpretacion
	useEffect(() => {

		//console.log('EXPEDIENTE => ' + expediente );
		setIsLoading(true);

		const requestInterpretacion = async () => {
			const resp = await axios.get(BASE_URL + `/procedureinterpretacion/${expediente}`, {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
				withCredentials: true,
			});
			
			//console.log('OBJETO RESPUESTA ' + resp.data);
			setContentInterpretacion(resp.data);
			//console.log('CONTENIDO' + contentInterpretacion);
		};

		if (expediente)
			requestInterpretacion();	
		
			setIsLoading(false);

	}, [expediente]);


	return (
		<form onSubmit={handleSubmit}>
			<div className={styles.searchBox}>
				<div>
					<div className={styles.formButton} onClick={()=>getRedirect(claveExpediente)}>
						imagenes
					</div>
				</div>
				{ rolePatient && 
				<div>
					<div className={`${styles.formButton} ${stylesModal.openModalBtn}`}					
					onClick={() => {
						doClickShare();
					}}>
						Compartir
					</div>
				</div>				
				}
				{ contentInterpretacion && <ExpedientePDF contentInterpretacion = {contentInterpretacion} objetoExpediente = { objetoExpediente } /> }

				{modalOpen && <Modal 
												setOpenModal={setModalOpen} 
												handleShare={handleShare} 
												infoShare={infoShare} 
												handlePhone={handlePhone} 
												setHandlePhone={setHandlePhone} />}  

				
				{/* { viewLink && <LinkAccess wsdlPaciente = { objetoExpediente?.wsdl_paciente } /> } */}
				{ (viewLink && objetoExpediente) && <LinkAccess objetoExpediente = { objetoExpediente } /> }

			</div>
		</form>
	);
}
