/**
 *
 * @returns 
 */

import styles from "./css/Question.module.css";

const Question00 = ({ setAvance, setProgressBar }) => {

	const handleEvent = () => {
		setAvance(1);
		//setProgressBar(10);
	} 
 

	return (
		<>
			<div className={styles.containerQuestion}>
				<div className={styles.titleQuestion}>
				Agradecemos su disponibilidad para participar en la siguiente encuesta.
				</div>
				<div className={styles.containerOptions}>
					<button onClick={() => handleEvent()} className={styles.controlButton}>Iniciar</button> 
				</div>
			</div>
		</>
	);
};

export default Question00;


