import { Link, useNavigate } from "react-router-dom";
import axios, { BASE_URL } from "./../api/axios"

import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./css/RegisterPage.module.css";
import AlertCustom from './../config/AlertCustom'
import { TipoMensaje } from './../config/TiposDatos'
import logo from './../assets/logo.png';

const RegisterPage = () => {

	const mensajeError = "Obligatorio 7 caracteres";
	const navigate = useNavigate();

	const {
		handleChange,
		handleSubmit,
		handleBlur,
		errors,
		touched, 
		values
	} = useFormik({
		initialValues: {
			tagID: "",
			securityCode : "",
			nameProfile: "",
			emailProfile: "",
			passwordProfile: "",
			passwordRepeatProfile: ""
		},
		onSubmit: async (values) => {

			const bodyRequest = {
				tagID : values.tagID,
				securityCode : values.securityCode,
				nameProfile : values.nameProfile,
				emailProfile : values.emailProfile,
				passwordProfile : values.passwordProfile
			}

			try {
				const response = await axios.post(BASE_URL + '/auth/register',
						bodyRequest,
						{
								headers: {'Content-Type':'application/json', 'Access-Control-Allow-Origin' : '*'},
								withCredentials: true,
						}
				);

				const accessToken = response?.data?.jwtToken;
				const role = response?.data?.roleUser;
				
				localStorage.setItem('ciemToken', JSON.stringify(accessToken));
				localStorage.setItem('ciemRole', JSON.stringify(role));

				//setAuth({ user, pwd, roles, accessToken });				
				AlertCustom(TipoMensaje.ERROR, `Se ha enviado un correo a la cuenta ${values.emailProfile} para verificar autenticidad y activación de la cuenta.`);						
				navigate('/login');

		} catch (err) {
				if (!err?.response) {
					AlertCustom(TipoMensaje.ERROR, 'El servidor web no responde');
				} else if (err.response?.status === 204) {
					AlertCustom(TipoMensaje.ERROR, 'El correo, usuario y código de seguridad no corresponden al usuario.');
				} else if (err.response?.status === 401) {
					AlertCustom(TipoMensaje.ERROR, 'El correo y contraseña ya existen para otra cuenta. Contacte a CIEM para validar información.');
				} else if (err.response?.status === 400) {
						AlertCustom(TipoMensaje.ERROR, 'Acceso no autorizado');
				} else {
						AlertCustom(TipoMensaje.ERROR, 'Fallo general');						
				}				
		}


		},
		validationSchema: Yup.object({
			tagID: Yup.string()
				.min(7, mensajeError)
				.max(7, mensajeError)
				.required("Es obligatorio"),
			securityCode: Yup.string()
				.min(7, mensajeError)
				.max(7, mensajeError)
				.required("Es obligatorio"),
			
			nameProfile: Yup.string()
				.min(2, 'Es obligatorio')
				.max(30)
				.required("El nombre del perfil es obligatorio"),

			emailProfile: Yup.string()
				.email("Ingrese un correo válido")
				.max(255)
				.required("El correo electrónico es obligatorio"),
			passwordProfile: Yup.string()
				.max(255)
				.required("La contraseña es obligatoria"),
			passwordRepeatProfile: Yup.string()
				.oneOf([Yup.ref('passwordProfile'), null], 'Las contraseñas deben ser iguales')				
		}),
	});

	return (
		<div className="main-content">
			<div className={styles.login}>
				<div className={styles.card}>
					<div className={styles.loginItem}></div>

					<div className={styles.loginItem}>
						<img className={styles.logo} src={logo} alt="Logo"></img>
						<h1 className={styles.titleForm}>Registro</h1>
					</div>
					<div className={styles.loginItem}>
						{/* <p className={styles.subTitleForm}>Defina el perfil para su cuenta CIEM&reg;.</p> */}
					</div>

					<div className={styles.loginItem}>
						<form action="" noValidate onSubmit={handleSubmit}>							
							<div>
								<p>Información de acceso como invitado; se encuentra en la parte superior derecha del resultado de su estudio.</p>
							<input
									type="text"
									className={`${styles.formInputGuess} ${styles.textField}`}
									name="tagID"
									placeholder="Usuario"
									value={values.tagID}
									maxLength={7}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p className={styles.parrafo}>
									{touched.tagID && errors.tagID && (
										<span className={styles.titleAlert}>
											{" "}
											{errors.tagID}{" "}
										</span>
									)}
								</p>								
							<input
									type="text"
									className={`${styles.formInputGuess} ${styles.textField}`}
									name="securityCode"
									placeholder="Código de seguridad"
									value={values.securityCode}
									maxLength={7}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p className={styles.parrafo}>
									{touched.securityCode && errors.securityCode && (
										<span className={styles.titleAlert}>
											{" "}
											{errors.securityCode}{" "}
										</span>
									)}
								</p>									
							</div>
							<p>Información para su cuenta CIEM&reg;</p>
							<div className={styles.formEmail}>
								<input
									type="text"
									className={`${styles.formInput} ${styles.textField}`}
									name="nameProfile"
									placeholder="Nombre para su perfil"
									maxLength={40}
									value={values.nameProfile}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p className={styles.parrafo}>
									{touched.nameProfile && errors.nameProfile && (
										<span className={styles.titleAlert}>
											{" "}
											{errors.nameProfile}{" "}
										</span>
									)}
								</p>	
								<input
									type="text"
									className={`${styles.formInput} ${styles.textField}`}
									name="emailProfile"
									placeholder="Correo electrónico"
									value={values.emailProfile}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p className={styles.parrafo}>
									{touched.emailProfile && errors.emailProfile && (
										<span className={styles.titleAlert}>
											{" "}
											{errors.emailProfile}{" "}
										</span>
									)}
								</p>								</div>

							<div className={styles.formPassword}>
								<input
									type="password"
									className={`${styles.formInput} ${styles.textField}`}
									name="passwordProfile"
									placeholder="Contraseña" 
									value={values.passwordProfile}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p className={styles.parrafo}>
									{touched.passwordProfile && errors.passwordProfile && (
										<span className={styles.titleAlert}>
											{" "}
											{errors.passwordProfile}{" "}
										</span>
									)}
								</p>	
							</div>
							<div className={styles.formPassword}>
								<input
									type="password"
									className={`${styles.formInput} ${styles.textField}`}
									name="passwordRepeatProfile"
									placeholder="Confirme su contraseña"
									value={values.passwordRepeatProfile}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p className={styles.parrafo}>
									{touched.passwordRepeatProfile && errors.passwordRepeatProfile && (
										<span className={styles.titleAlert}>
											{" "}
											{errors.passwordRepeatProfile}{" "}
										</span>
									)}
								</p>	
							</div>

							<div>
								<button 
									type="submit"
									className={`${styles.formButton} ${styles.textFieldButton}`}
								>
									Registrarse
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegisterPage;
