import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { TypeAuth } from "../config/TiposDatos";
import { useUser, useDispatch } from "../context/GlobalProvider";
import styles from './css/Navbar.module.css';

function Navbar() {

	//const navigate = useNavigate();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleClose = (event) => {
		event.preventDefault();
		dispatch({type : TypeAuth.authLogout})
		navigate("/", { replace: true });
	}

	const user = useUser();
	return (
		<div className="nav-main navbar-content">
			<nav className="nav"> 
					<NavLink to="/" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>	 
						<span className={styles.navbarItem}>CIEM</span>				
					</NavLink>  

				<ul className="navbar-menu"> 
					<li className={styles.navbarItem}> 
						
						{/* { (user.user && user.user.clave !== -1) ? <span className={styles.navbarItem}> {user?.user.nombre} </span>  : <span className={styles.navbarItem}>Invitado</span> } */}
						{ (user.user && user.user.clave !== -1) && <span className={styles.navbarSesion}> {user?.user.nombre} </span> }
						
						{ (user.user && user.user.clave !== -1) &&
						<button className={styles.formButton} onClick={(event)=>handleClose(event) }>Cerrar sesión</button>
						}
					</li>
					{/* <li className="navbar-item">
						<NavLink to="/registro" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
							Registrarse
						</NavLink>
					</li> */}
					{/* <li className="navbar-item">
						<NavLink to="/busqueda" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
							Búsqueda
						</NavLink>
					</li> */}
					{/* <li className="navbar-item">
						<NavLink to="/public" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
							Perfil
						</NavLink>
					</li> */}
				</ul>
			</nav>
		</div>
	);
}

export default Navbar;
