/**
 *
 * @returns 
 */
import styles from "./css/Question.module.css";

const Question01 = ({ setHandleEvent, setAvance, setProgressBar }) => {

	const handleEvent = (value) => {
		
		//setProgressBar(25);
		setAvance(2);
		setHandleEvent(prevState => ({
			...prevState,
			value01 : value}));		
	}		
	  
	return (
		<>
			<div className={styles.containerQuestion}>
				<div className={styles.titleQuestion}>
					¿Se encuentra satisfecho con el trato que recibió en recepción?
				</div>
				<div className={styles.containerOptions}>
					<div className={styles.controlButton} onClick={() => handleEvent(1)}>Sí</div>
					<div className={styles.controlButton } onClick={() => handleEvent(0)} >No</div>
				</div>
			</div>
		</>
	);
};

export default Question01;
