/**
 *
 * @returns
 */
import { useState, useEffect } from 'react';

import styles from "./css/SurveyMessage.module.css";

export const SurveyMessage = ({ setSurvey, numeroAcceso, modalidad }) => {

	const transformedStyle = {
		visibility: "visible",
		position: "fixed",
	
		bottom: "50px",
		right: "50px",
	
		padding: "20px",
		//borderRadius: "10px",
		//width: "220px",
		//height: "30px",
		//background: "#666",
		//boxShadow: "5px 5px 5px 0.5",
		transition: "1s, transform 2s",
		
  	color: '#fff',

  	zIndex: '2',
  	height: '120px',  
  	width: '350px',
  	borderRadius: '30px 10px',

  	backgroundColor: 'var(--ciem-color-secondary',
  	//textAlign: 'center',
  	//fontSize: '1.1rem',
  
  
  	boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.5)',
  	display: 'flex',
  	flexDirection: 'column',
  	justifyContent: 'center',
  	alignItems: 'center'
	};
	
	const normalStyle = {
		visibility: "hidden",
		position: "fixed",
		marginBottom: "50px",
		marginRight: "100px",
	};

	const [bigSize, setBigSize] = useState(false);

	useEffect(() => {
    const timer = setTimeout(() => {
      setBigSize(!bigSize);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

	const handleSurvey = (ev) => {
		setSurvey(true);

		localStorage.setItem("survey", "1");

		// 187.188.165.14:3031/
		//console.log(`http://localhost:3031/${modalidad}/${numeroAcceso}`);
		//window.open(`http://localhost:3031/${modalidad}/${numeroAcceso}`, "_blank");
		
		window.open(`http://187.188.165.14:3031/#/${modalidad}/${numeroAcceso}`, "_blank");
	};

	return (
		<>

			<div style={bigSize ? transformedStyle : normalStyle} className={styles.surveyContainer} onClick={() => handleSurvey()}>
				<div className={styles.iconSurvey}>
					<i className="fa fa-thumbs-up"></i>{" "}
					<span className={styles.separatorUpDown} />
					<i className="fa fa-thumbs-down"></i>
				</div>

				<div className={styles.message}>
					<p>Tu opinión nos interesa. Cuéntanos tu experiencia.</p>
				</div>
			</div>
		</>
	);
};
