import React from "react";
import "./css/Footer.css";

export default function Footer() {
	return (
		<>
			<footer className="footer-content">
				<div className="footer-social">
					<div className="social-item">
						<a href="https://api.whatsapp.com/send?phone=529931358999&text=Bienvenido a CIEM, ¿en que podemos apoyarle el día de hoy?" target="_blank" rel="noreferrer">
							<i className="fa fa-whatsapp social-icon"></i>
						</a> 
					</div>
					<div className="social-item">
						<a href="https://bit.ly/3NCSlVy" target="_blank" rel="noreferrer">
							<i className="fa fa-facebook-f social-icon"></i>
						</a>
					</div>
					<div className="social-item">
						<a href="https://www.instagram.com/ciem_tab" target="_blank" rel="noreferrer">
							<i className="fa fa-instagram social-icon"></i>
						</a>
					</div>
					<div className="social-item">						
						<a href="mailto:atencion@ciemradiologos.com">						
							<i className="fa fa-envelope social-icon"></i>
						</a>
					</div> 
					<div className="social-item">
						<a href="http://www.ciemradiologos.com/" target="_blank" rel="noreferrer">
							<i className="fa fa-globe social-icon"></i>
						</a>
					</div>					
				</div>
				<div className="footer-bottom">
					<p>&copy; Derechos Reservados 2024 CIEM&reg; Radiólogos</p>
				</div>
			</footer>
		</>
	);
}
