import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./css/PasswordRecoveryPage.module.css";

const PasswordRecoveryPage = () => {
	const {
		handleChange,
		handleSubmit,
		handleBlur,
		errors,
		touched,
		values,
	} = useFormik({
		initialValues: {
			emailLogin: "",
			passwordLogin: "",
		},
		onSubmit: (values) => {
			console.log(values);
			//doSubmit(values);
		},
		validationSchema: Yup.object({
			emailLogin: Yup.string()
				.email("Ingrese un correo válido")
				.max(255)
				.required("El correo electrónico es obligatorio")
		}),
	});

	return (
		<div className="main-content">
			<div className={styles.login}>
				<div className={styles.card}>					
					<div className={styles.loginItem}>
						<h2 className={styles.parrafo}>Recuperar su contraseña</h2>
					</div>
					<div>
						<p className={styles.smallBlack}>Indique su correo electrónico registrado en la plataforma CIEM&reg;. Le enviaremos un enlace para restaurar su contraseña.</p>
					</div>

					<div className={styles.loginItem}>
						<form noValidate onSubmit={handleSubmit}>
							<div className={styles.formEmail}>
								<input							
									type="text"									
									className={`${styles.formInput} ${styles.textField}`}
									name="emailLogin"
									placeholder="Correo electrónico"
									value={values.emailLogin}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p className={styles.parrafo}>
									{touched.emailLogin && errors.emailLogin && (
										<span className={styles.titleAlert}> {errors.emailLogin} </span>
									)}
								</p>
							</div>

							<div>
								<button type="submit"
								className={`${styles.formButton} ${styles.textField}`}>
									Recuperar su acceso
								</button>
							</div>

							{/* <div>
								<strong className={styles.smallBlack}>
									Clic aquí para ir al inicio
								</strong>
							</div> */}
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PasswordRecoveryPage;
