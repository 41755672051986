/**
 *
 * @returns 
 */
 import styles from "./css/Question.module.css";

 const Question03 = ({ setHandleEvent, setAvance }) => {

	const handleEvent = (value) => {
		
		//setProgressBar(90);
		//referencedByApp ? setAvance(8) : setAvance(7)
		setAvance(7)
		setHandleEvent(prevState => ({
			...prevState,
			value03 : value}));		
	}	   
  
   return (
     <>
       <div className={styles.containerQuestion}>
         <div className={styles.titleQuestion}>
         ¿Se suscitó algún inconveniente al momento de comunicarse con nosotros?
         </div>
         <div className={styles.containerOptions}>
           <div className={styles.controlButton} onClick={() => handleEvent(1)}>Sí</div>
           <div className={styles.controlButton} onClick={() => handleEvent(0)}>No</div>
         </div>
       </div>
     </>
   );
 };
 
 export default Question03;
 