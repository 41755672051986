import { TypeRole, TypeAuth } from "../config/TiposDatos";

const initialUser = {
	user: { clave: -1, correo: "None", nombre: "None", role: TypeRole.ROLE_NONE },
};

const userReducer = (state, action) => {
	switch (action.type) {
		case TypeAuth.authLogout:
			return {
				...state,
				user: null,
			};

		case TypeAuth.authLogin:
			console.log(action.payload);
			return {
				...state,
				user: action.payload,
			};

		default:
			return state;
	}
};

export { initialUser };
export default userReducer;
