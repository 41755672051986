import styles from "./css/Search.module.css";
import { FaSearch } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useQuery } from "../hooks/useQuery";
import { formatDate } from "../utils/utilsDate";


export function SearchExpediente({ 
			setDateInitValue, 
			setDateFinishValue, 
			setNameValue, 
			nameValue, 
			dateInitValue, 
			dateFinishValue, 
			setExecuteFinder, 			
			executeFinder }) {
	const query = useQuery();

	
	const [dateToday, setDateToday] = useState(null);
	

	useEffect(() => {
		setNameValue('');
		setDateToday(formatDate(new Date()).split(' '));
	}, []);
	

	const handleSubmit = (ev) => {
		ev.preventDefault();		
		setExecuteFinder(!executeFinder);
	};

	const handleInitDate = (ev) => {
		const dateValue = ev.target.value;
    console.log("dateINITValue", dateValue);
    setDateInitValue(dateValue);
	}

	const handleFinishDate = (ev) => {
		const dateValue = ev.target.value;
    console.log("dateFINISHValue", dateValue);
    setDateFinishValue(dateValue);
	}

	const handleText = (ev) => {
		setNameValue(ev.target.value);
	}

	return (
		<form className={styles.searchContainer} onSubmit={handleSubmit}>
			<div className={styles.searchBox}>
				
				<label htmlFor="inputDateInit">Desde:</label>
        <input
          className={styles.formInput}
          name="dateInit"
          type="date"
					value={dateInitValue}
					max={dateToday}
					onChange={(e) => handleInitDate(e)}
        />

        <label htmlFor="inputDateFinish">Hasta:</label>
        <input
          className={styles.formInput}
          type="date"
          name="dateFinish"
					value={dateFinishValue}
					max={dateToday}
					onChange={(e) => handleFinishDate(e)}
        />         
				<input
					className={styles.formInput}
					type="text"
					placeholder="Paciente"
					value={nameValue}
					onChange={(e) => handleText(e)}
				/>
				<button
					className={`${styles.formButton} ${styles.colorImage}`}
					type="submit"
				>
					<FaSearch size={20} /> 					
				</button> 


				{/* <button className={styles.formButton} type="submit">Buscar pacientes</button>				   */}

			</div>
		</form>
	);
}
