import { toast  } from 'react-toastify';
import { TipoMensaje } from './TiposDatos';


const AlertCustom = (tipoMensaje, message) => {

  const options = {
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: true,
  }
  
  switch (tipoMensaje) {
    case TipoMensaje.SUCCESS : 
      toast.success(message, options); 
      break;

    case TipoMensaje.INFORMATION : 
      toast.info(message, options); 
      break;

    case TipoMensaje.WARNING : 
      toast.warn(message, options); 
      break;

    case TipoMensaje.ERROR : 
      toast.error(message, options); 
      break;

    default : 
      toast(message, options); 
      break;
    
  }  
}

export default AlertCustom