import { SearchExpediente } from "./../components/SearchExpediente";
import { ExpedientePage } from "./ExpedientePage";
import { useUser } from "../context/GlobalProvider"; 
import { TypeRole } from '../config/TiposDatos';
import { formatDate } from "../utils/utilsDate";
import { useState } from "react";

export function Expedientes() {

  const { user } = useUser(); 
  //
	//const [dateInitValue, setDateInitValue] = useState(formatDate(new Date()).split(' '));


  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultDate = date.toLocaleDateString('en-CA');  
    
	const [dateInitValue, setDateInitValue] = useState(defaultDate);
	const [dateFinishValue, setDateFinishValue] = useState(defaultDate);
  
  const [nameValue, setNameValue] = useState('');
  const [executeFinder, setExecuteFinder] = useState(false);

  return (   
    <div>
      {/* { user?.nombre !== 'None' && <Search /> } */}
      <SearchExpediente  
        setDateInitValue = { setDateInitValue }
        setDateFinishValue = { setDateFinishValue }
        nameValue = { nameValue }
        setNameValue = { setNameValue }
        dateInitValue = { dateInitValue }
        dateFinishValue = { dateFinishValue }
        setExecuteFinder = { setExecuteFinder }      
        executeFinder = { executeFinder }
        />
      <ExpedientePage 
        setDateInitValue = { setDateInitValue }
        setDateFinishValue = { setDateFinishValue }
        setExecuteFinder = { setExecuteFinder }
        executeFinder = { executeFinder }
        dateInitValue = { dateInitValue }
        dateFinishValue = { dateFinishValue }      
        nameValue = { nameValue }

      />
    </div>
  );
}