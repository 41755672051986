
import { useEffect, /*useReducer,*/ useState } from "react";


import styles from "./css/Valorame.module.css";

import Question00 from "../components/Question00";
import Question01 from "../components/Question01";
import Question02 from "../components/Question02";
import Question03 from "../components/Question03";

import QuestionMessageFinal from "../components/QuestionMessageFinal";
import Thanks from "../components/Thanks";

import { useParams } from "react-router-dom";
import Modalities from "../components/Modalities";
import QuestionContact from "../components/QuestionContact";
import QuestionCustom from "../components/QuestionCustom";


const typesModalities = {
	CR: "CR",
	US: "US",
	DM: "DM",
	MG: "MG",
	CT: "CT",
	MR: "MR",
};

function Valorame() {
	
	const { mode, access } = useParams();	

	const [avance, setAvance] = useState(0);
	const [referenceCustomModality, setReferenceCustomModality] = useState(false);

	const [handleEvent, setHandleEvent] = useState({
		numeroAcceso: access,
		value01: -1, // Satisfecho con el trato
		value02: -1, // Indicaciones proporcionadas
		value03: -1, // Algun inconveniente
		value04: "", // Sugerencia o comentario
		phone: "",
		patient: "",
	});

/*
	const initialState = {
		CR: false,
		US: false,
		DM: false,
		MG: false,
		CT: false,
		MR: false,
	};
*/
	const initialState = [
		{ modality : "CR", active : false, value : 1 },
		{ modality : "US", active : false, value : 2 },
		{ modality : "DM", active : false, value : 3 },
		{ modality : "MG", active : false, value : 4 },
		{ modality : "CT", active : false, value : 5 },
		{ modality : "MR", active : false, value : 6 }
	];


	const [modalities, setModalities] = useState(initialState);	
	const [referencedByApp, setReferencedByApp] = useState(false);

	const [resultModalities, setResultModalities] = useState([]);


		// Inicializa la modalidad que viene en el params
	useEffect(() => {

		if (access) {
			
			// Activamos la modalidad que se pasa referenciada desde la aplicacion CIEM Web
			const values = modalities.map(item => {
				if (item.modality === mode) {
					const update = {};
					update.modality = mode;
					update.active = true;
					update.value = item.value;
					return update 
				}
				else	
					return item
			});
			
			//console.log(values);
			//console.log(handleEvent);

			setModalities((prevState) => (
				[...values]
			));			
			
			setReferencedByApp(true);
		} 
	}, [access]);

	return (
		<>
			<div className={styles.container}>
			
				<div className={styles.main}>
					{ avance === 0 && (                        
						<Question00 
								
								setAvance={setAvance} />
					)}

					{ avance === 1 && (
						<Question01 
								setHandleEvent={setHandleEvent} 
								
								setAvance={setAvance}/>
					)}
 
					{ avance === 2 && (
						<Question02 
								setHandleEvent = { setHandleEvent } 								
								setAvance = { setAvance }
								referencedByApp = { referencedByApp }
							/>
					)}

					{ (!referencedByApp && avance === 3) &&
						<Modalities 
								modalidades 		= {modalities} 
								setModalidades 	= {setModalities}
								typesModalities = {typesModalities}
								
								setAvance 			= { setAvance }
								setReferenceCustomModality = { setReferenceCustomModality }>
						</Modalities>
					}

					{ ((referencedByApp || referenceCustomModality) && avance === 4) && (
						<QuestionCustom 
								modalities 			= { modalities }
								resultModalities= { resultModalities } 
								setResultModalities = { setResultModalities }
								setModalities		= { setModalities }
								setHandleEvent 	= { setHandleEvent } 
								
								setAvance 			= { setAvance }								
						 />
					)}		

					{ avance === 6 && (
						<Question03 
								setHandleEvent = { setHandleEvent } 								
								setAvance = { setAvance }																
						/>
					)}


				{ (avance === 7) && (
						<QuestionContact 
								setHandleEvent = { setHandleEvent } 
								
								setAvance = { setAvance }
								setModalities = { setModalities }
						/>
					)}


					{ (avance === 8) && (
						<QuestionMessageFinal 
								setHandleEvent = { setHandleEvent } 
								
								setAvance = { setAvance }
								data = { handleEvent }
								setModalities = { setModalities }
								resultModalities = { resultModalities }
						/>
					)}

					{ avance === 9 && (
						<Thanks/>
					)}
				</div>
			</div>			
		</>
	);
}


export default Valorame;
