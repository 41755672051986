import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import styles from "./css/LoginPage.module.css";

import AlertCustom from '../config/AlertCustom';
import { TipoMensaje } from '../config/TiposDatos';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useRef } from "react";
import axios, { BASE_URL } from "./../api/axios"


import logo from './../assets/logo.png';
import { useDispatch } from "../context/GlobalProvider";
import { TypeAuth } from "../config/TiposDatos";

const LoginPage = () => {
 
	const userRef = useRef();

	const dispatch = useDispatch();

	const navigate = useNavigate();
	//const location = useLocation();
	//const from = location.state?.from?.pathname || "/";	

	useEffect(() => {
		userRef.current.focus();
	}, []);

	const {
		handleChange,
		handleSubmit,
		handleBlur,
		errors,
		touched, 
		values,
		isValid,
	} = useFormik({
		initialValues: {
			emailLogin: "",
			passwordLogin: "",
		},
		onSubmit: async (values) => {

			const bodyRequest = {
				email : values.emailLogin,
				password : values.passwordLogin
			}

			try {
				//const API_URL="http://localhost:5020/api";
 
				const response = await axios.post(BASE_URL + '/auth/login',
						bodyRequest,
						{
								headers: {'Content-Type':'application/json', 'Access-Control-Allow-Origin' : '*'},
								withCredentials: true,
						}
				); 
				

				const { roleUser } = response?.data;

				const accessToken = response?.data.jwtToken;
				const role = roleUser;

				//const accessToken = response?.data?.jwtToken;
				console.log('role user -> ' + JSON.stringify(role));
				
				localStorage.setItem('ciemToken', JSON.stringify(accessToken));
				localStorage.setItem('ciemRole', JSON.stringify(role));

/**
 *     // Generamos TOKEN con el EMAIL y ROLE para el control de la sesión y peticiones desde el FRONTEND.
    const roleUser = {
      email,
      perfilID : user.rows[0].perfil,
      ClaveUsuario,
      perfilDesc : user.rows[0].perfil=== 7 ? 'ROLE_MEDIC' : 'ROLE_PATIENT'
    }  
 */

				// user: { clave: -1, correo: "None", nombre: "None", role: "None" }				
				const usuario = {
					clave : roleUser.ClaveUsuario,
					correo :  roleUser.email,
					nombre : roleUser.nombre,
					role : roleUser.perfilDesc
				}

				dispatch({type : TypeAuth.authLogin, payload : usuario});				
				navigate('/expediente');
		

		} catch (err) {
				if (!err?.response) {
					AlertCustom(TipoMensaje.ERROR, 'El servidor web no responde');
				} else if (err.response?.status === 401) {
					AlertCustom(TipoMensaje.ERROR, 'Su correo y contraseña son incorrectos.');
				} else if (err.response?.status === 400) {
						AlertCustom(TipoMensaje.ERROR, 'Acceso no autorizado');
				} else {
						AlertCustom(TipoMensaje.ERROR, `Fallo general ${err.response}`);						
				}
				//useRef.current.focus();
		}


		},
		validationSchema: Yup.object({
			emailLogin: Yup.string()
				.email("Ingrese un correo válido")
				.max(255)
				.required("El correo electrónico es obligatorio"),
			passwordLogin: Yup.string()
				.max(255)
				.required("La contraseña es obligatoria"),
		}),
	});
 
	return ( 
		<div className="main-content">
			<div className={styles.login}>
				<div className={styles.card}>
					<div className={styles.loginItem}></div>
					<div className={styles.loginItem}>
						{/* <h1 className={styles.parrafo}> CIEM</h1> */}
						<img className={styles.logo} src={logo} alt="Logo"></img>
					</div>
					<div>
						<p className={styles.subTitleForm}>Indique su correo y contraseña para iniciar sesión</p>
					</div>

					<div className={styles.loginItem}>
						<form noValidate onSubmit={handleSubmit}>
							<div className={styles.formEmail}>
								<input
									type="text"
									className={styles.formInput}
									name="emailLogin"
									ref={userRef}
									placeholder="Correo electrónico"
									value={values.emailLogin}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p className={styles.parrafo}>
									{touched.emailLogin && errors.emailLogin && (
										<span className={styles.titleAlert}>
											{" "}
											{errors.emailLogin}{" "}
										</span>
									)}
								</p>
							</div>

							<div>
								<input
									type="password"
									className={styles.formInput}
									name="passwordLogin"
									placeholder="Contraseña"
									value={values.passwordLogin}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p className={styles.parrafo}>
									{touched.passwordLogin && errors.passwordLogin && (
										<span className={styles.titleAlert}>
											{" "}
											{errors.passwordLogin}{" "}
										</span>
									)}
								</p>
							</div>
							<div>
								<button className={styles.formButton} type="submit" disabled={!isValid ? true : false}>
									Iniciar sesión
								</button>
							</div>
							<div className={`${styles.parrafoLink} ${styles.smallText}`}>
								¿No tiene una cuenta?
								<Link className={styles.smallBlack} to="/invitado"> Ingrese como invitado</Link>								
							</div>
							
							<div className={`${styles.parrafoLink} ${styles.smallText}`}>
								¿Olvidó su contraseña?								
								<Link className={styles.smallBlack} to="/recuperacion"> Recupérela aquí</Link>								
							</div>
						  

							{/* <p className="my-4 text-sm flex justify-between px-3">
        Already have an Account?
        <Link to="/login" className="text-blue-700 hover:text-blue-900">
          Login
        </Link>
      </p> */}
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
