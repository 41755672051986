import axios from 'axios';


//export const BASE_URL = 'http://187.188.165.14:5020/api';
export const BASE_URL = 'https://ciem-app.com:5020/api'; // production domain digitalOcean
//export const BASE_URL = 'http://192.168.2.4:5050/api';
//export const BASE_URL = 'http://localhost:5050/api';
//export const BASE_URL = 'http://192.168.1.159:5050/api';
//export const BASE_URL = 'http://24.199.65.142:5020/api';

export default axios.create({    
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Methods' : 'POST, GET, OPTIONS, DELETE, PUT'},
    withCredentials: true,
    rejectUnauthorized: false
});

/**
 *xhr.setRequestHeader("Access-Control-Allow-Origin", "https://localhost");
xhr.setRequestHeader("Access-Control-Allow-Headers", "Content-Type");
xhr.setRequestHeader("Access-Control-Allow-Methods", "OPTIONS");
 */

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
