/**
 *
 * @returns
 */
import { useState } from "react";
import styles from "./css/Question.module.css";

const Modalities = ({ setModalidades, modalidades, typesModalities, setAvance, setProgressBar, setReferenceCustomModality }) => {
	
	const handleEvent = () => {
    setAvance(4);    
		setReferenceCustomModality(true);
	}	

	const [selectedModality, setSelectedModality] = useState(false);

	const handleChange = (ev, mode) => {
							
			const temps = modalidades.map(item => {								
				if (item.modality === mode) {
					const update = {};
					update.modality = item.modality;
					update.active = !item.active;
					update.value = item.value;
					return update 
				}
				else	
					return item
			});
		
			
			setModalidades((prevState) => (
				[...temps]
			));

			let counter = 0;
			temps.map(item => {
				counter = item.active ? counter + 1  : counter;
			});

			//console.log(counter);
			setSelectedModality(counter > 0);
	};

	 
	return (
		<>
			<div className={styles.containerQuestion}>
				<div className={styles.titleQuestion}>
					Seleccione la, o las modalidades de estudio que se realizó
				</div>
				<label className={styles.containerCheck}>
					Rayos X
					<input
						type="checkbox"
						name="CR"
						checked={modalidades[0].active}
						onChange={(ev) => handleChange(ev, typesModalities.CR)}
					/>
					<span className={styles.checkmark}></span>
				</label>

				<label className={styles.containerCheck}>
					Ultrasonido
					<input
						type="checkbox"
						name="US"
						checked={modalidades[1].active}
						onChange={(ev) => handleChange(ev, typesModalities.US)}
					/>
					<span className={styles.checkmark}></span>
				</label>

				<label className={styles.containerCheck}>
					Densitometría
					<input
						type="checkbox"
						name="DM"
						checked={modalidades[2].active}
						onChange={(ev) => handleChange(ev, typesModalities.DM)}
					/>
					<span className={styles.checkmark}></span>
				</label>

				<label className={styles.containerCheck}>
					Mastografía
					<input
						type="checkbox"
						name="MG"
						checked={modalidades[3].active}
						onChange={(ev) => handleChange(ev, typesModalities.MG)}
					/>
					<span className={styles.checkmark}></span>
				</label>

				<label className={styles.containerCheck}>
					Tomografía
					<input
						type="checkbox"
						name="CT"
						checked={modalidades[4].active}
						onChange={(ev) => handleChange(ev, typesModalities.CT)}
					/>
					<span className={styles.checkmark}></span>
				</label>

				<label className={styles.containerCheck}>
					Resonancia Magnética
					<input
						type="checkbox"
						name="MR"
						checked={modalidades[5].active}
						onChange={(ev) => handleChange(ev, typesModalities.MR)}
					/>
					<span className={styles.checkmark}></span>
				</label>

				<div className={styles.containerOptions}>
					<button onClick={() => handleEvent()} disabled={!selectedModality} className={styles.controlButton}>Siguiente</button> 
				</div>
			</div>
		</>
	);
};

export default Modalities;
