import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    parrafo: {
      paddingTop: 0,
      paddingBottom: 0,
      margin: 3,
      fontSize: 9,
      textAlign: 'justify',
    }
  });
  
const depuraTag = (texto) => {
    const textoTemp = texto.replace('<B>', '');
    return textoTemp.replace('</B>', '');
}

const PDFParrafo = ({ contentInterpretacion, objetoExpediente }) => {    
    // objetoExpediente.contenido_interpretacion
    const rows = contentInterpretacion.map(item =>
        <View key={item.clave}>
            <Text style={styles.parrafo}>{depuraTag(item.parrafo)}</Text> 
        </View>
    );
    return (<Fragment>{rows}</Fragment>)
};

export default PDFParrafo