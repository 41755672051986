

const Questions = [

{ 
  index       : 1,
  modality    : 'CR',
  description : 'En estudio de RAYOS X ¿cómo fue el trato del técnico radiólogo?',
  active      : 0
},

{
  index       : 2,
  modality    : 'US',
  description : 'En estudio de ULTRASONIDO, ¿cómo fue el trato del auxiliar de consultorio?',
  active      : 0
},

{
  index       : 3,
  modality    : 'DM',
  description : 'En estudio de DENSITOMETRÍA ¿cómo fue el trato del técnico radiólogo?',
  active      : 0
},

{
  index       : 4,
  modality    : 'MG',
  description : 'En estudio de MASTOGRAFÍA ¿cómo fue el trato del técnico radiólogo?',
  active      : 0
},

{
  index       : 5,
  modality    : 'CT',
  description : 'En estudio de TOMOGRAFÍA ¿cómo fue el trato del técnico radiólogo?',
  active      : 0
},

{
  index       : 6,
  modality    : 'MR', 
  description : 'En estudio de RESONANCIA MAGNÉTICA ¿cómo fue el trato del técnico radiólogo?',
  active      : 0
}
]

export default Questions;



/*
				
{handleEvent.estado === 4 && modalities.CR && (
  <QuestionModality setHandleEvent={setHandleEvent} messageQuestion={'En estudio de Rayos X ¿cómo fue el trato del técnico radiólogo?'} />
)}

{handleEvent.estado === 4 && modalities.US && (
  <QuestionModality setHandleEvent={setHandleEvent} messageQuestion={'En estudio de ULTRASONIDO, ¿cómo fue el trato del auxiliar de consultorio?'} />
)}

{handleEvent.estado === 4 && modalities.DM && (
  <QuestionModality setHandleEvent={setHandleEvent} messageQuestion={'En estudio de Densitometría ¿cómo fue el trato del técnico radiólogo?'} />
)}

{handleEvent.estado === 4 && modalities.MG && (
  <QuestionModality setHandleEvent={setHandleEvent} messageQuestion={'En estudio de Mastografía ¿cómo fue el trato del técnico radiólogo?'} />
)}

{handleEvent.estado === 4 && modalities.CT && (
  <QuestionModality setHandleEvent={setHandleEvent} messageQuestion={'En estudio de Tomografía ¿cómo fue el trato del técnico radiólogo?'} />
)}

{handleEvent.estado === 4 && modalities.MR && (
  <QuestionModality setHandleEvent={setHandleEvent} messageQuestion={'En estudio de Resonancia Magnética ¿cómo fue el trato del técnico radiólogo?'} />
)}

*/