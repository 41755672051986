import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Spinner } from "../components/Spinner";
import axios, { BASE_URL } from "./../api/axios";
import styles from "./css/ExpedienteDetails.module.css";
import { FormExpediente } from "./FormExpediente";
 
import AlertCustom from "../config/AlertCustom";
import { BASE_URL_PAGE, TypeRole } from "../config/TiposDatos";
import { SurveyMessage } from "./SurveyMessage";
import QRGenerator from "./pdfcomponent/QRGenerator";


export function ExpedienteDetails() {



	const { idHash } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [expediente, setExpediente] = useState(null);

	const [rolePatient, setRolePatient] = useState(false);

	const [survey, setSurvey] = useState(false);

	useEffect(() => {
		setIsLoading(true);

		const requestSurvey = async (numeroAcceso) => {
			const resp = await axios.get(BASE_URL + `/survey/${numeroAcceso}`, {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
				withCredentials: true,
			});

			setSurvey(resp.data?.survey === '1');
			setIsLoading(false);
		};

		const request = async () => {
			try {

				setIsLoading(true);
				const response = await axios.get(
					BASE_URL + `/procedures/${idHash}`,
					{
						headers: { "Content-Type": "application/json" },
						withCredentials: true,
					}
				);

				const role = JSON.parse(localStorage.getItem("ciemRole"));
				setRolePatient(role?.perfilDesc === TypeRole.ROLE_PATIENT || role?.perfilDesc ===  TypeRole.ROLE_GUESS);
				//setRegresar(role?.perfilDesc === TypeRole.ROLE_PATIENT || role?.perfilDesc === TypeRole.ROLE_MEDIC);

				setExpediente(response?.data);
				requestSurvey(response.data.numero_acceso);

				setIsLoading(false);

			} catch (err) {
				console.log(err);
				setIsLoading(false);
				setExpediente(null);
			}
		};

		request();
		setIsLoading(false);
	}, []);


	const doLogPacs = async (paramNumeroAcceso) => {

		console.log(' NUMERO_ACCESO => ' + JSON.stringify(paramNumeroAcceso));
		const bodyRequest = {
			numeroAcceso : paramNumeroAcceso
		}

		try {			 
			const response = await axios.post(BASE_URL + '/logpacs', bodyRequest,	{
							withCredentials: true,
					}
			);
			console.log(response.data);

		} catch (err) {
			console.log(err);
		}
	}

	const getRedirect = async (clave) => {
			// Antes de lanzar las imagenes, guardamos  en el log el acceso del paciente o medico, con la finalidad de tener estadisticas reales de consultas al PACS
			doLogPacs(expediente?.numero_acceso);

			// Se hace la petición al servidor fujifilm para visualización de las imágenes.
			// window.open en safari mobile no funciona
			window.open(expediente?.wsdl_paciente, '_blank');
			
			// alternativa encontrada en internet para safari mobile
			//window.location.assign(expediente?.wsdl_paciente)
	};

	const getURL = (hash) => {
		return `${BASE_URL_PAGE}/invitado/p/${hash}`
	} 

		if (isLoading) {
			return <Spinner />;
		}
		
		return (
		<>
			<div className={styles.detailsContainer}>
				{ (!survey && rolePatient ) && 
					ReactDOM.createPortal(
						<SurveyMessage
							setSurvey={setSurvey} 
							numeroAcceso = {expediente?.numero_acceso} 
							modalidad = {expediente?.codigo_modalidad}/>,
						document.querySelector("#portal")
					)} 

	
				<FormExpediente
					expediente={expediente?.cve_procedimiento_paciente}
					claveExpediente={expediente?.numero_acceso}
					objetoExpediente={expediente}
					getRedirect={getRedirect}
					setIsLoading={setIsLoading}
				/>
				
				<div className={styles.col}>
					<p className={styles.item}>
						Estudio:<strong> {expediente?.procedimiento.toUpperCase()}</strong>
					</p>
					<p className={styles.item}>
						Fecha:<strong> {expediente?.fecha}</strong>
					</p>
					<p className={styles.item}>
						Paciente:<strong> {expediente?.paciente}{" "}
						{expediente?.apellidos}</strong>
					</p>
				</div> 
								 

				{/* {expediente && <ExpedientePDF expediente={expediente}></ExpedientePDF>}  */}

				<div className={styles.container}>
					<div className={styles.colorTexto}	
						dangerouslySetInnerHTML={{
							__html: expediente?.contenido_interpretacion,
						}}
						></div>
					
				</div>
				<div className={styles.col}>
					<p className={styles.item}>
						<strong>{expediente?.medico_radiologo}</strong>
					</p>
					<p className={styles.item}>
						{expediente?.descripcion_profesional}{" "}
						{expediente?.cedula_profesional}
					</p>
				</div>								
			</div>
			< QRGenerator url={getURL(expediente?.hash_autenticidad)} />

		</>
	);
}
