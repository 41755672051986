import { createContext, useContext, useReducer } from "react";
import userReducer, { initialUser } from "./userReducer";

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {   
    const [userGlobal, dispatch] = useReducer(userReducer, initialUser);

    return (
	<GlobalContext.Provider value={{userGlobal, dispatch}}>
		{children}
	</GlobalContext.Provider>
    )
}

const useUser = () => useContext(GlobalContext).userGlobal;
const useDispatch = () => useContext(GlobalContext).dispatch;

export { GlobalContext, useUser, useDispatch };
export default GlobalProvider;
