import styles from "./css/FormExpediente.module.css";

import AlertCustom from '../config/AlertCustom';
import { TipoMensaje } from '../config/TiposDatos';

const LinkAccess = ( { objetoExpediente } ) => {

	const doClickLink = () => {		
    console.log(objetoExpediente);
    navigator.clipboard.writeText(objetoExpediente.wsdl_paciente).then(() => {
      AlertCustom(TipoMensaje.SUCCESS, 'Copiado al portapapeles');
    }, () => {
      AlertCustom(TipoMensaje.ERROR, 'Error al copiar enlace');
    });
	}	

  const unsecuredCopyToClipboard = (content) => { 
      const textArea = document.createElement("textarea"); 
      textArea.value=content; 
      document.body.appendChild(textArea); 
      textArea.focus();
      textArea.select(); 
      try {
        document.execCommand('copy');      
      }
      catch (err) {
        console.error('Unable to copy to clipboard',err);
        AlertCustom(TipoMensaje.SUCCESS, 'Unable to copy to clipboard');
      }
      document.body.removeChild(textArea)
    };

  /**
   * Copies the text passed as param to the system clipboard
   * Check if using HTTPS and navigator.clipboard is available
   * Then uses standard clipboard API, otherwise uses fallback
  */
  const copyToClipboard = () => {
    const contentMail =   `\n`
                        + `Agradeciendo su confianza, nos permitimos hacerle llegar el acceso al estudio: ${objetoExpediente?.procedimiento.toUpperCase()} `
                        + `\n`
                        + `que se realizó el paciente: ${objetoExpediente?.paciente.toUpperCase()} ${objetoExpediente?.apellidos.toUpperCase()} `
                        + `en el Centro de Imagenología de Especialidades Médicas.`
                        + `\n`
                        + `\n`
                        + `Si desea visualizar las imágenes, haga clic en el siguiente enlace: `
                        + `\n`
                        + `\n`
                        + `${objetoExpediente?.wsdl_paciente}`
                        + `\n`
                        + `\n`
                        + `Saludos cordiales.`

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(contentMail);      
    } else {
      unsecuredCopyToClipboard(contentMail);
    }
    AlertCustom(TipoMensaje.SUCCESS, 'Copiado al portapapeles');
  };  

  return (    
    <div className={styles.formButton}
    onClick={() => { copyToClipboard(); }}>
      Copiar enlace
    </div>  
  )
}

export default LinkAccess


