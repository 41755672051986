import React from "react";
import style from "./Modal.module.css";
import logo from "./assets/code.png";

function Modal({ setOpenModal, handleShare, setHandlePhone, handlePhone, infoShare }) {
 
	return (
		<div className={style.modalBackground}>
			<div className={style.modalContainer}>
				<div className={style.title}>
					<h1>Compartir estudio</h1>
				</div>
				<div className={style.body}>
					<div className={style.topModal}>
						<p>
							El <span>usuario</span> y <span>código de seguridad</span>{" "}
							han sido activados por 24 h
						</p>
						<p>A quien se le haga entrega de esta información confidencial, tendrá la disponibilidad de consultar dicho estudio
						</p>
            <p>
              Si así lo desea, ingrese un número telefónico para hacer uso de la <span>plataforma Whatsapp.</span>
            </p>
					</div>
				</div>
				<div>
					<div>
						<p>Usuario: <span className={style.estiloInfo}>{infoShare?.ciemTag} </span></p>
						<p>Código : <span className={style.estiloInfo}>{infoShare?.securityCode} </span></p>
					</div>
				</div>	
        <div className={style.containerInput}>
            <input className={style.formInput} maxLength={10} value={handlePhone} type="tel" placeholder="Teléfono (10 dígitos)" onChange={(e) => setHandlePhone(e.target.value)}/>
        </div>


				<div className={style.containerCode}>
					<img className={style.shareCode} src={logo} alt="Compartir"></img>
				</div>

				<div className={style.footer}>
					<button
						className={style.share}
						onClick={() => {
							handleShare();
						}}
						// id="ShareBtn"
					>
						Whatsapp
					</button>

					<button
						className={style.close}
						onClick={() => {
							setOpenModal(false);
						}}
						// id="CloseBtn"
					>
						Cerrar
					</button>
				</div>
			</div>
		</div>
	);
}

export default Modal;
