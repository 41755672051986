/**
 * 
 */

 export const TypeRole = {
  ROLE_NONE       : 'ROLE_NONE',
  ROLE_PATIENT    : 'ROLE_PATIENT',
  ROLE_MEDIC      : 'ROLE_MEDIC',
  ROLE_RADIOLOGY  : 'ROLE_RADIOLOGY',
  ROLE_GUESS      : 'ROLE_GUESS',
  ROLE_CHR_MEDIC  : 'm',
  ROLE_CHR_PATIENT: 'p',
}


export const TipoMensaje = {
  SUCCESS: 'success',
  INFORMATION: 'info',
  WARNING: 'warn', 
  ERROR: 'error' 
};

export const TipoAccionReducer = {
  LOGIN : 'login',
  REGISTRO : 'registro',
  LOGOUT : 'logout'
}

export const TypeAuth = {
	authLogin: "auth - login",
	authLogout: "auth - logout",
};

export const BASE_URL_PAGE = 'https://ciem-app.com/#';
 

// export { TipoRoles, TipoAccionReducer }
// export default TipoMensaje; 
