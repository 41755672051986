import { PDFViewer, PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import { PDFInterpretacion } from "./PDFInterpretacion";
import styles from "./../../components/css/FormExpediente.module.css";
 
export function ExpedientePDF({ contentInterpretacion, objetoExpediente }) {
	return (
	<div className={styles.formButton}>

    <BlobProvider className={styles.formButton}  
                  			document={<PDFInterpretacion 
													contentInterpretacion = { contentInterpretacion } 
													objetoExpediente = { objetoExpediente }/>} 
									
                >
                  {({ url, blob, loading }) => {
                    return (
                      <a href={url} style={{textDecoration:'none', color:'#ffffff'}} target="_blank">
                        Interpretación
                      </a>
                    );
                  }}
    </BlobProvider>  
				
	</div>	
		
				
	);
}
 