/**
 *
 * @returns
 */
 import { useState } from "react";
import styles from "./css/Question.module.css";
import Questions from "./data/Questions";

 const QuestionCustom = ({ modalities, setAvance, setResultModalities }) => {


    const listQuestions = modalities.filter(item => {
      return item.active;
    });


    listQuestions.map(item => {
      Questions.map(question => {
        if (item.modality === question.modality) {
          item.question = question.description;
        }
      })
    });

    const [currentQuestion, setCurrentQuestion] = useState(0);

  	const handleModalities = (value) => {

      const result = {
        modality : listQuestions[currentQuestion].modality,
        value : value
      }

		  setResultModalities(prevState => ([
			  ...prevState, result]));		

      if (currentQuestion < listQuestions.length -1) {
        setCurrentQuestion(currentQuestion + 1);
      }
      else
        setAvance(6)

	  }	
 
   return (
     <>
       <div className={styles.containerQuestion}>
         <div className={styles.titleQuestion}>
          { listQuestions[currentQuestion].question }
         </div>
         <div className={styles.containerOptions}>
           <div className={styles.controlButtonT} onClick={() => handleModalities(3)}>Excelente</div>
           <div className={styles.controlButtonT} onClick={() => handleModalities(2)}>Bueno</div>
           <div className={styles.controlButtonT} onClick={() => handleModalities(1)}>Regular</div>
           <div className={styles.controlButtonT } onClick={() => handleModalities(0)}>Malo</div>
         </div>
       </div>       
     </>
   );
 };
 
 export default QuestionCustom;
 