/**
 *
 * @returns
 */
import { useState } from "react";
import styles from "./css/Question.module.css";

const QuestionContact = ({ setAvance, setHandleEvent }) => {

	const [visibleContact, setVisibleContact] = useState(false);

	const handleContact = (ev) => {
		if (ev === 1 ) {
			setVisibleContact(!visibleContact)
		}
		else {
    	setAvance(8);
    	//setProgressBar(90);					
		}		
	}

	const handleForm = (value) => {
    setAvance(8);
    //setProgressBar(90);	
	};

	const handlePatient = (value) => {
		setHandleEvent(prevState => ({
			...prevState,
			patient : value.target.value}));
	};

	const handlePhone = (value) => {
		setHandleEvent(prevState => ({
			...prevState,
			phone : value.target.value}));
	};


	return (
		<>
			<div className={styles.containerQuestion}>
				{ (!visibleContact) &&
					<>
						<div className={styles.titleQuestion}>
							¿Desea ser contactado vía telefónica?
						</div>
						<div className={styles.containerOptions}>
							<div
								className={styles.controlButtonT}
								onClick={() => handleContact(1)}
								>
								si
							</div>
							<div
								className={styles.controlButtonT}
								onClick={() => handleContact(0)}
								>
								no
							</div>
						</div>
					</>
				}
				{ (visibleContact) &&
					<>
					<div className={styles.containerMessage}>
						<div className={styles.titleQuestion}>
							Indique el nombre del paciente y teléfono de contacto
						</div>
						<input
							type="text"
							className={`${styles.mensajeFinal} ${styles.inputText}`}
							value={setHandleEvent.patient}
							onChange={(ev) => handlePatient(ev)}
							placeholder="Nombre del paciente"
						/>

						<input
							type="text"
							className={`${styles.mensajeFinal} ${styles.inputText}`}
							value={setHandleEvent.phone}
							onChange={(ev) => handlePhone(ev)}
							placeholder="Teléfono"
						/>
						<div
							className={styles.controlButtonT}
							onClick={() => handleForm(1)}
						>
							Siguiente
						</div>
					</div>	
				</>
				}			
			</div>
		</>
	);
};

export default QuestionContact;



/************************************************************************************************************************
 * 				DISABLE BUTTON BY TEXT
 */
/************************************************************************************************************************

/*

import React, { useState } from "react";

function EnableOnInputChange() {
  const [text, enableButton] = useState("");

  const handleTextChange = (event) => {
    enableButton(event.target.value);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Your state values: \n 
            text: ${text} \n 
            You can replace this alert with your process`);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Enter Text</label>
        <input
          type="text"
          name="text"
          placeholder="Enter Text"
          onChange={handleTextChange}
          value={text}
        />
      </div>
      
      <button type="submit" disabled={!text}>
        Enable
      </button>
    </form>
  );
}




// ***************************************************************************************************************** 

import React, { useState } from "react";

export default function App() {
  const [name, setName] = useState();

  return (
    <div>
      <input onChange={(e) => setName(e.target.value)} />
      <button disabled={!name}>save</button>
    </div>
  );
}


*/