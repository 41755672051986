import axios, { BASE_URL } from "./../api/axios"
import { Link, useNavigate } from "react-router-dom";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";

import AlertCustom from '../config/AlertCustom';
import { TipoMensaje, TypeRole } from '../config/TiposDatos';

import styles from "./css/InvitadoPage.module.css";
import logo from './../assets/logo.png';
import { useState } from "react";
import { useDispatch } from "../context/GlobalProvider";
import { TypeAuth } from "../config/TiposDatos";


const InvitadoPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [expediente, setExpediente] = useState(-1);
	
	const mensajeError = "Obligatorio 7 caracteres";

	const {
		handleChange,
		handleSubmit,
		handleBlur,
		errors,
		touched,
		values,
		// isValid,
	} = useFormik({
		initialValues: {
			tagID: "",
			codigoSeguridad: "",
		},
		onSubmit: async (values) => {
			try {
				const response = await axios.get(BASE_URL + `/procedures/${values.tagID}/${values.codigoSeguridad}`,
						//{
						//		headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*'},
						//		withCredentials: true,
						//}
				);
         
				setExpediente(response.data.clave);

				//console.log(response.data);
				if (response.data?.clave === -1)  {
					AlertCustom(TipoMensaje.ERROR, 'El usuario y código de seguridad son incorrectos.');									
					console.log('es clave = -1 y return -1');
					setExpediente({clave : -1});
					return (null);
				}

				const role =  {
					email : '',
					nombre : '',
					ClaveUsuario : -1,
					perfilDesc : TypeRole.ROLE_GUESS, // 'ROLE_GUESS',
					perfilID : -1
				}
				//console.log(role);
				localStorage.setItem('ciemRole', JSON.stringify(role));

				dispatch({type : TypeAuth.authLogin, payload : role});	
				navigate(`/expediente/${response.data.hash_autenticidad}`);
				//navigate(`/expediente/${response.data.clave}`);

		} catch (err) {
				if (!err?.response) {
					AlertCustom(TipoMensaje.ERROR, 'El servidor web no responde');
				} else if (err.response?.status === 401) {
					AlertCustom(TipoMensaje.ERROR, 'El usuario y código de seguridad son incorrectos.');
				} else if (err.response?.status === 400) {
						AlertCustom(TipoMensaje.ERROR, 'Acceso no autorizado');
				} else {
						AlertCustom(TipoMensaje.ERROR, 'Fallo general');						
				}
		}						

		},
		validationSchema: Yup.object({
			tagID: Yup.string()
				.min(7, mensajeError)
				.max(7, mensajeError)
				.required("Es obligatorio"),
			codigoSeguridad: Yup.string()
				.min(7, mensajeError)
				.max(7, mensajeError)
				.required("Es obligatorio"),
		}),
	});

	return (
		<div className="main-content">
			<div className={styles.login}>
				<div className={styles.card}>
					<div className={styles.loginItem}></div>
					<div className={styles.loginItem}>
						<img className={styles.logo} src={logo} alt="Logo"></img>
						<h2 className={styles.titleForm}>Invitado CIEM<span className={styles.smallBlack}>&reg;</span></h2>
					</div>
					<div>
						<p className={styles.subTitleForm}>Indique su usuario y código de seguridad, este se encuentra en la parte superior derecha de sus resultados.</p>
					</div>

					<div className={styles.loginItem}>
						<form action="" noValidate onSubmit={handleSubmit}>
							<div>
								<input
									className={styles.formInput}
									type="text"
									name="tagID"
									placeholder="Usuario"
									maxLength="7"
									value={values.tagID}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p>
									{touched.tagID && errors.tagID && (
										<span className={styles.titleAlert}> {errors.tagID} </span>
									)}
								</p>
							</div>

							<div>
								<input 
									className={styles.formInput}
									type="text"
									name="codigoSeguridad"
									placeholder="Código de seguridad"
									maxLength="7"
									value={values.codigoSeguridad}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<p>
									{touched.codigoSeguridad && errors.codigoSeguridad && (
										<span className={styles.titleAlert}> {errors.codigoSeguridad} </span>
									)}
								</p>
							</div>

							<div className={styles.formSubmit}>
								<button
									type="submit"	
									className={styles.formButton}								
								>
									Ingresar
								</button>
							</div>

							<div className={`${styles.parrafoLink} ${styles.smallText}`}>
								¿Tiene un correo y contraseña CIEM&reg;?								
								<Link className={styles.smallBlack} to="/login"> Inicie sesión</Link>								
							</div>

							<div className={`${styles.parrafoLink} ${styles.smallText}`}>
								¿No tiene una cuenta?								
								<Link className={styles.smallBlack} to="/registro"> Regístrese</Link>								
							</div>

						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InvitadoPage;
