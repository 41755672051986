import React from 'react';
import styles from "./css/Question.module.css";

class Thanks extends React.Component {
  constructor(props) {
    super(props);
    const x = 0;
    // Initializing the state 
    //this.state = { color: 'lightgreen' };
  }
  componentDidMount() {
  
    const redirectCIEM = () => {            
      //window.close();
      window.open('https://www.ciemradiologos.com', '_self');
    };

    setTimeout(redirectCIEM, 2000);
  }

  render() {
    return (
      <>
			<div className={styles.containerQuestion}>
				<div className={styles.titleQuestion}>
				Agradecemos la confianza que nos ha brindado para realizar sus estudios; esperamos atenderle nuevamente.
				</div>
			</div>
		</>
    );
  }
}
export default Thanks;