import React from "react";
import QRCode from "qrcode.react";

const QRGenerator = ({ url }) =>  {
  return (
    <div hidden={true}>
      <QRCode
        id="qr-code"
        value={url}
        size={500}
        bgColor="#FFF"
        fgColor="#000"
        includeMargin={false}
        level={"L"}  
      />
    </div>
  );
}

export default QRGenerator;
