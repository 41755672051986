import { useEffect, useState } from "react";
import { ExpedienteCard } from "./../components/ExpedienteCard";
import styles from "./css/ExpedientePage.module.css";
import { Spinner } from "./../components/Spinner";
import { Empty } from "../components/Empty";

import AlertCustom from "../config/AlertCustom";
import { TipoMensaje, TypeRole } from "../config/TiposDatos";

import axios, { BASE_URL } from "./../api/axios";
import { useParams, useNavigate } from "react-router-dom";

export function ExpedienteOnePage() {
	const [expedientes, setExpedientes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { type, hash } = useParams();

	const navigate = useNavigate();
  
	// De forma similar a componentDidMount y componentDidUpdate
	useEffect(() => {
		setIsLoading(true);
		const doExecute = async () => {
			try {
				console.log(type);
				const response = await axios.get(
					BASE_URL + `/procedures/guess/${hash}`,
					{
						headers: {
							"Content-Type": "application/json",
							"Access-Control-Allow-Origin": "*",
						},
						withCredentials: true,
					}
				);

				setExpedientes(response?.data);

				setIsLoading(false);

				const role =  {
					email : '',
					nombre : '',
					ClaveUsuario : -1,
					perfilDesc : type === TypeRole.ROLE_CHR_MEDIC ? TypeRole.ROLE_MEDIC : TypeRole.ROLE_PATIENT,
					perfilID : -1
				}
				//console.log(role);
				localStorage.setItem('ciemRole', JSON.stringify(role));

			} catch (err) {
				if (!err?.response) {
					AlertCustom(TipoMensaje.ERROR, "El servidor web no responde");
				} else if (err.response?.status === 401) {
					AlertCustom(TipoMensaje.ERROR, "No se encontraron coindencias");
				} else if (err.response?.status === 400) {
					AlertCustom(TipoMensaje.ERROR, "Acceso no autorizado");
				} else {
					AlertCustom(TipoMensaje.ERROR, "Fallo general");
				}
				setExpedientes({});
			}
		};

		doExecute();

	}, []);

	const doHome = () => {
		setTimeout(() => {
			navigate("/");
		}, 5000)
	}

	if (isLoading) {
		return <Spinner />;
	}

	if (!isLoading && expedientes?.length === 0) {
		doHome();
		return <Empty mensaje="Expediente no encontrado" />;
	}

	return (
		<div>
			<ul className={styles.expedienteGrid}>
				{expedientes &&
					expedientes.map((exp) => (
						<ExpedienteCard key={exp.hash_autenticidad} expediente={exp} />
					))}
			</ul>
		</div>
	);
}
