
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

import logoCIEM from "./assets/logo-ciem.png";
import piePagina from "./assets/pie-pagina.png";
import PDFParrafo from './PDFParrafo';

import R1 from "./assets/AngelNino.png";
import R2 from "./assets/JorgeArmin.png";
import R3 from "./assets/Marcela.png";
import R4 from "./assets/Alfredo.png";
import R5 from "./assets/Javier.png";
import R6 from "./assets/MariaAngeles.png";
import R7 from "./assets/Nayeli.png";
import R8 from "./assets/Pineda.png";
import R9 from "./assets/Gemila.png";


// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 10,
    lineHeight: 1,
    display: 'flex',
    flexDirection : 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',     
    paddingBottom: 30
  },
  logoQR: {
    width: 70,
    height: 70,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logoCIEM: {
    position: 'absolute',
    width: 160,
    height: 50,
    left: 0,
  },
  logoFirma : {
    position: 'absolute',
    bottom: 50,
    width: 400, 
    height: 100,
    paddingLeft: 200,
  },
  logoPIE: {
    position: 'absolute',
    width: 600,
    height: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
    bottom: 0
  },
  titulo: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 3,
    fontSize: 8,
    fontWeight: 700,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'justify',
  },
  margenSuperior : {
    marginTop: 60
  },
  texto: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 3,
    fontSize: 8,
  },
  textoColor: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#11847d',
    margin: 3,
    fontSize: 8,
  },
  infoPaciente : {
    paddingBottom: 30
  },
  textoFecha: {
    position: 'absolute',
    right: 0,    
    top: 55,
    fontSize: 9,
  },    
  configQR: {  
    position: 'absolute',  
    right: 0,
    left: 340,    
    display: 'flex',
    flexDirection: 'row'    
  },
  textoConfig: {
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: 0.5,
    margin: 5,
    fontSize: 8,
  },
  textoConfigColor: {
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: 0.5,
    color: '#11847d',
    margin: 3,
    fontSize: 8,
  }    
});
 

const dateExpediente = (dateParam) => {  
  if (dateParam) {
    const currentMonth = dateParam.toString().substring(4,6);
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

    return `Villahermosa Tabasco a ${dateParam.toString().substring(6, 8)} de ${months[parseInt(currentMonth)-1]} de ${dateParam.toString().substring(0, 4)}`;    
  }
  return 'Villahermosa Tabasco'
}
 
// Create Document Component
export const PDFInterpretacion = ({ contentInterpretacion, objetoExpediente }) => (

  <Document>
    <Page size="A4" style={styles.page} >
      <View style={styles.header}>

	  	  <Image style={styles.logoCIEM} src={logoCIEM} />
        <View style={styles.configQR}>
          <View>
            <Text style={styles.textoConfigColor}>consulta en: ciem-app.com</Text> 
            <Text style={styles.textoConfig}>Usuario: {objetoExpediente?.ciem_tag}</Text> 
            <Text style={styles.textoConfig}>Codigo seg.: {objetoExpediente?.codigo_seguridad}</Text> 
          </View>
          <Image allowDangerousPaths style={styles.logoQR} src={document.getElementById('qr-code')?.toDataURL()} />
        </View>
      </View>
      <View>
        <Text style={styles.textoFecha}>{dateExpediente(objetoExpediente?.fecha_registro)}</Text> 
      </View>  

      <View style={styles.margenSuperior}></View>    

      <View style={styles.infoPaciente}>
            <Text style={styles.titulo}>{objetoExpediente?.medico.toUpperCase()} {objetoExpediente?.medico_apellidos.toUpperCase()}</Text> 
            <Text style={styles.titulo}>PRESENTE:</Text> 
            <Text style={styles.titulo}></Text> 
            <Text style={styles.titulo}>PACIENTE: {objetoExpediente?.paciente} {objetoExpediente?.apellidos}</Text> 
            <Text style={styles.titulo}>ESTUDIO SOLICITADO: {objetoExpediente?.procedimiento.toUpperCase()}</Text>             
        </View>      
	  	<PDFParrafo 
        contentInterpretacion ={ contentInterpretacion } 
        objetoExpediente = { objetoExpediente }
      />   		

      <Image style={styles.logoFirma} src={ objetoExpediente?.codigo_usuario === 'R1' ? R1 : 
                                          objetoExpediente?.codigo_usuario === 'R2' ? R2 :
                                          objetoExpediente?.codigo_usuario === 'R3' ? R3 :
                                          objetoExpediente?.codigo_usuario === 'R4' ? R4 :
                                          objetoExpediente?.codigo_usuario === 'R5' ? R5 :
                                          objetoExpediente?.codigo_usuario === 'R6' ? R6 : 
                                          objetoExpediente?.codigo_usuario === 'R7' ? R7 : 
                                          objetoExpediente?.codigo_usuario === 'R8' ? R8 : R9 

                                        } />

      <Image style={styles.logoPIE} src={piePagina} />
    </Page>

  </Document>
);